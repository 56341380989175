import { Route } from '@angular/router';

export const claimDetailsRoute: Route = {
    loadComponent: () => import('./claim-details.component'),
    children: [
        // TODO: Redirect to overview if we have that ported
        { path: '', redirectTo: 'payments', pathMatch: 'full' },
        {
            path: 'payments',
            loadComponent: () => import('./payments-tab/payments-tab.component'),
        },
        {
            path: 'payments/invoice/:invoiceId',
            loadComponent: () => import('./payments-tab/invoice-details/invoice-details.component'),
        },
        {
            path: 'documents',
            loadComponent: () => import('./documents-tab/documents-tab.component'),
        },
    ],
};
